<template>
  <skeleton class="card-skeleton" />
</template>

<script>
import Skeleton from '@/components/Skeletons/Skeleton'

export default {
  name: 'CardSkeleton',
  components: {
    Skeleton
  }
}
</script>

<style lang="scss">
.card-skeleton {
  @include margin(top, 10);

  display: grid;
  grid-template-columns: 1fr;
  height: 20vh;

  &::after {
    background: $gray5;
    background: linear-gradient(0deg, $gray5 0%, rgba($gray5, 0) 100%);
    bottom: 0;
    content: '';
    height: 30%;
    position: absolute;
    width: 100%;
  }
}
</style>
