<template>
  <section
    class="interview-date-time"
    data-test="interview-timestamp"
  >
    {{ formattedStartsAt }}
  </section>
</template>

<script>
export default {
  name: 'InterviewDateTime',
  props: {
    startsAt: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    formattedStartsAt () {
      const today = this.$moment().startOf('day')
      const interviewDate = this.$moment(this.startsAt)
      const daysBetween = interviewDate.diff(today, 'days')
      const datesFormat = {
        0: this.$t(`${this.langPath}.today`),
        1: this.$t(`${this.langPath}.tomorrow`)
      }

      const date = datesFormat[daysBetween] || interviewDate.format('DD/MM/YYYY')
      const time = interviewDate.format('HH[h]mm')
      return `${date}, ${time}`
    }
  }
}
</script>
