<template>
  <div class="skeleton-loader">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Skeleton'
}
</script>

<style lang="scss">
.skeleton-loader {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: gradientMove;
  animation-timing-function: linear;
  background: $gray5;
  background-image:
    -webkit-gradient(
      linear,
      left center,
      right center,
      from($gray5),
      color-stop(0.2, $white),
      color-stop(0.4, $gray5),
      to($gray5)
    );
  background-image:
    -webkit-linear-gradient(
      left,
      $gray5 0%,
      $white 20%,
      $gray5 50%,
      $gray5 100%
    );
  border-radius: $base;
  overflow: hidden;
  position: relative;
}

@keyframes gradientMove {
  0% {
    background-position: -490px 0;
  }

  100% {
    background-position: 490px 0;
  }
}
</style>
