<template>
  <div v-if="loadingInterviews">
    <card-skeleton data-test="interviews-card-skeleton" />
  </div>
  <section v-else class="interviews-card">
    <h1
      class="interviews-card__title"
      data-test="title"
    >
      {{ $t(`${langPath}.title`) }}
    </h1>
    <section
      v-if="hasInterviews"
      class="interviews-card__list"
    >
      <highlighted-interview
        class="interviews-card__highlighted-interview"
        data-test="first-interview"
        :interview="firstInterview"
        @cancel-interview="setInterviewBeingCancelled"
        @reschedule-interview="handleRescheduleInterview"
        @open-candidate-modal="handleOpenCandidateModal"
        @open-position="handleOpenPosition"
      />
      <h5
        v-if="otherInterviews.length"
        class="interviews-card__upcoming-title"
        data-test="upcoming-title"
      >
        {{ $t(`${langPath}.upcomingTitle`) }}
      </h5>
      <interview-details
        v-for="interview in otherInterviews"
        :key="interview.id"
        :data-test-interview-id="interview.id"
        class="interviews-card__upcoming"
        :interview="interview"
        @cancel-interview="setInterviewBeingCancelled"
        @reschedule-interview="handleRescheduleInterview"
        @open-candidate-modal="handleOpenCandidateModal"
        @open-position="handleOpenPosition"
      />
      <section class="interviews-card__footer">
        <ev-button
          v-if="hasPagesToLoad"
          data-test="see-more-link"
          :disabled="loadingMoreInterviews"
          variant="tertiary"
          color="blue"
          size="small"
          @click="handleSeeMore"
        >
          <ev-circle-loader
            v-if="loadingMoreInterviews"
            variant="tertiary"
          />
          <span v-else>{{ $t(`${langPath}.seeMore`) }}</span>
        </ev-button>
        <section class="interviews-card__footer-tooltip">
          <how-to-use-your-schedule />
        </section>
      </section>
    </section>
    <section
      v-else
      class="interviews-card__empty-state"
    >
      <ev-icon
        class="interviews-card__empty-state-icon"
        name="calendar-alt"
        color="blue"
        size="medium"
        data-test="empty-state-icon"
      />
      <p
        data-test="empty-state-subtitle"
        class="interviews-card__empty-state-subtitle"
      >
        {{ $t(`${langPath}.emptyState.subtitle`) }}
      </p>
      <p
        data-test="empty-state-description"
        class="interviews-card__empty-state-description"
      >
        {{ $t(`${langPath}.emptyState.description`) }}
      </p>
      <section class="interviews-card__empty-state-actions">
        <ev-link
          a-href="/#/companies/employers/candidates"
          data-test="empty-state-button"
        >
          {{ $t(`${langPath}.emptyState.button`) }}
        </ev-link>
      </section>
    </section>
    <interview-cancellation-modal
      v-if="interviewBeingCancelled"
      data-test="interview-cancellation-modal"
      :interview="interviewBeingCancelled"
      @confirm="handleInterviewCancellationConfirm"
      @close="handleInterviewCancellationClose"
    />
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { INTERVIEWS } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import InterviewCancellationModal from './InterviewsCard/InterviewCancellationModal'
import HighlightedInterview from './InterviewsCard/HighlightedInterview'
import InterviewDetails from './InterviewsCard/InterviewDetails'
import CardSkeleton from './skeletons/CardSkeleton'
import HowToUseYourSchedule from './HowToUseYourSchedule'
import { TheNotificationCardList } from '@revelotech/everest'

const interviewsHelper = createNamespacedHelpers(INTERVIEWS)

export default {
  name: 'InterviewsCard',
  components: {
    CardSkeleton,
    EvButton,
    EvCircleLoader,
    EvIcon,
    EvLink,
    InterviewCancellationModal,
    HighlightedInterview,
    InterviewDetails,
    HowToUseYourSchedule
  },
  data () {
    return {
      langPath: __langpath,
      interviewBeingCancelled: null
    }
  },
  computed: {
    ...interviewsHelper.mapState([
      'upcomingInterviews',
      'upcomingInterviewsPage',
      'upcomingInterviewsTotalPages',
      'loadingInterviews',
      'loadingMoreInterviews'
    ]),
    hasInterviews () {
      return this.upcomingInterviews.length > 0
    },
    hasPagesToLoad () {
      return this.upcomingInterviewsPage < this.upcomingInterviewsTotalPages
    },
    firstInterview () {
      return this.upcomingInterviews[0]
    },
    otherInterviews () {
      return this.upcomingInterviews.slice(1)
    }
  },
  created () {
    this.getEmployersUpcomingInterviews()
  },
  methods: {
    ...interviewsHelper.mapActions([
      'getEmployersUpcomingInterviews', 'cancelInterview'
    ]),
    setInterviewBeingCancelled (interview) {
      this.interviewBeingCancelled = interview
    },
    async handleInterviewCancellationConfirm () {
      try {
        await this.cancelInterview(this.interviewBeingCancelled.id)

        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.cancellationSucceeded`),
          modifier: 'success'
        })
      } catch {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.cancellationFailed`),
          modifier: 'error'
        })
      } finally {
        this.interviewBeingCancelled = null
      }
    },
    handleInterviewCancellationClose () {
      this.interviewBeingCancelled = null
    },
    handleOpenCandidateModal (interview) {
      this.$router.push({
        name: 'ApplicantInterview',
        params: {
          applicantId: String(interview.applicantId),
          positionId: String(interview.positionId)
        }
      })
    },
    handleOpenPosition (interview) {
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: String(interview.positionId) }
      })
    },
    handleRescheduleInterview (interview) {
      const interviewDate = interview.scheduledStartAt.substr(0, 10)
      const interviewCalendar = this.$router.resolve({
        name: 'InterviewsNew',
        params: { applicantId: interview.applicantId },
        query: { reschedule: interview.id, interviewDate }
      })
      window.open(interviewCalendar.href, '_blank')
    },
    handleSeeMore () {
      this.getEmployersUpcomingInterviews(this.upcomingInterviewsPage + 1)
    }
  }
}
</script>

<style lang="scss">
.interviews-card {
  background: $white;
  border: 1px solid $gray5;
  border-radius: var(--b-radius);
  box-shadow: $shadow-8;
  height: auto;
  margin-top: $base*6;
  padding: 6*$base;
  width: 100%;

  &-title {
    font: var(--tx-title-5);
    margin-bottom: $base*10;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    min-width: 360px;

    &-tooltip {
      margin-left: auto;

      &-icon {
        margin-left: $base*2;
      }
    }
  }

  &__highlighted-interview {
    margin-bottom: $base*4;
  }

  &__title {
    @extend %h6;

    margin-bottom: $base*9;
  }

  &__upcoming-title {
    font: var(--tx-subtitle-2);
    margin-bottom: $base*5;
    margin-top: $base*6;
  }

  &__empty-state {
    text-align: center;

    &-icon.interviews-card__empty-state-icon {
      margin-bottom: $base*6;
    }

    &-subtitle {
      @extend %subtitle1;

      margin-top: $base*6;
    }

    &-description {
      @extend %body-text2;

      margin-top: $base*2;
    }

    &-actions {
      display: inline-block;
      margin-bottom: $base * 6;
      margin-top: $base * 6;
    }
  }
}
</style>
