<template>
  <app-modal class="interview-cancellation-modal" @close="$emit('close')">
    <template #header>
      <h5>{{ $t(`${langPath}.title`) }}</h5>
    </template>
    <main class="interview-cancellation-modal__body">
      <p
        class="interview-cancellation-modal__body__paragraph"
        data-test="cancellation-question"
      >
        {{ $t(`${langPath}.cancellationQuestion` ) }}
      </p>
      <div class="interview-cancellation-modal__details">
        <p
          class="interview-cancellation-modal__info--date"
          data-test="scheduled-start-at"
        >
          {{ interviewTimestamp }}
        </p>
        <p
          class="interview-cancellation-modal__info"
          data-test="candidate-name"
        >
          {{ interview.candidateName }}
        </p>
        <p
          class="interview-cancellation-modal__info--position-title"
          data-test="position-title"
        >
          {{ $t(`${langPath}.positionTitle`, { title: interview.positionTitle }) }}
        </p>
      </div>
      <p
        class="interview-cancellation-modal__body__paragraph"
        data-test="cancellation-implications"
      >
        {{ $t(`${langPath}.cancellationImplications`) }}
      </p>
    </main>
    <template #footer>
      <footer class="interview-cancellation-modal__footer">
        <ev-button
          class="button"
          variant="tertiary"
          color="blue"
          data-test="close-button"
          @click="$emit('close')"
        >
          {{ $t(`${langPath}.close`) }}
        </ev-button>

        <ev-button
          class="button"
          data-test="confirm-button"
          @click="$emit('confirm')"
        >
          {{ $t(`${langPath}.confirm`) }}
        </ev-button>
      </footer>
    </template>
  </app-modal>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'

export default {
  name: 'InterviewCancellationModal',
  components: {
    EvButton
  },
  props: {
    interview: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    interviewTimestamp () {
      const interviewDate = new Date(this.interview.scheduledStartAt)

      return this.$d(interviewDate, 'long')
    }
  }
}
</script>

<style lang="scss">
.interview-cancellation-modal {
  &__body {
    padding: $base*6 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    .button {
      @include margin(left, 4);
    }
  }

  &__details {
    padding: 4*$base;
  }

  &__info {
    font: var(--tx-body);

    &--position-title {
      color: $gray60;
      font: var(--tx-body-small);
    }

    &--date {
      color: $blue-light;
      font: var(--tx-caption);
    }
  }
}
</style>
