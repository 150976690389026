<template>
  <section
    v-if="displaysContent"
    class="interview-badge"
  >
    <ev-link
      v-if="hasLink"
      data-test="meeting-link"
      target="_blank"
      variant="secondary"
      color="blue"
      size="small"
      :a-href="mediumInfo"
    >
      {{ $t(`${langPath}.getIntoRoom`) }}
    </ev-link>
    <ev-badge
      v-else
      :title="badgeTitle"
      variant="gray"
      data-test="meeting-badge"
    />
  </section>
</template>

<script>
import EvBadge from '@revelotech/everestV2/EvBadge'
import EvLink from '@revelotech/everestV2/EvLink'

const mediumsWithLink = ['remote']

export default {
  name: 'InterviewBadge',
  components: {
    EvBadge,
    EvLink
  },
  props: {
    medium: {
      type: String,
      required: true
    },
    mediumInfo: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    displaysContent () {
      return this.medium !== 'other'
    },
    hasLink () {
      return mediumsWithLink.includes(this.medium)
    },
    badgeTitle () {
      return this.$t(`${this.langPath}.badges.${this.medium}`)
    }
  }
}
</script>

<style lang="scss">
.interview-badge {
  min-width: $base*30;

  .ev-link {
    display: inline;
  }
}
</style>
