<template>
  <figure class="applicant-avatar">
    <img
      v-if="avatarUrl"
      class="applicant-avatar__image"
      data-test="avatar"
      :alt="name"
      :src="avatarUrl"
    >
    <span
      v-else
      class="applicant-avatar__initials"
      data-test="initials"
    >
      {{ initials }}
    </span>
  </figure>
</template>

<script>
export default {
  name: 'ApplicantAvatar',
  props: {
    avatarUrl: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    initials () {
      const names = this.name.split(' ')
      const firstName = names[0]
      const lastName = names.reverse()[0]
      return `${firstName[0]}${lastName[0]}`
    }
  }
}
</script>

<style lang="scss" scoped>
.applicant-avatar {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
  height: $base*10;
  justify-content: center;
  overflow: hidden;
  width: $base*10;

  &__image {
    max-width: 100%;
    min-height: 100%;
  }

  &__initials {
    @extend %button1;

    background-color: $gray60;
    color: $gray20;
    height: 100%;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}
</style>
