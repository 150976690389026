<template>
  <div
    class="interview-details"
    data-test="interview-details"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    <span
      data-test="candidate-avatar-link"
      @click="$emit('open-candidate-modal', interview)"
    >
      <applicant-avatar
        :avatar-url="interview.candidateAvatar"
        :name="interview.candidateName"
        data-test="candidate-avatar"
      />
    </span>
    <section class="interview-details__info interview-details__item--left">
      <interview-date-time
        class="interview-details__timestamp"
        :starts-at="interview.scheduledStartAt"
        data-test="interview-timestamp"
      />
      <span
        class="interview-details__candidate-name"
        data-test="candidate-name"
        @click="$emit('open-candidate-modal', interview)"
      >
        {{ interview.candidateName }}
      </span>
      <span
        class="interview-details__position-title"
        data-test="position-title"
        @click="$emit('open-position', interview)"
      >
        {{ $t(`${langPath}.position`) }}: {{ interview.positionTitle }}
      </span>
    </section>
    <div class="interview-details__item--right">
      <app-dropdown-menu
        v-if="showMenu"
        data-test="menu"
      >
        <template slot="title">
          <ev-button
            variant="tertiary"
            color="blue"
            size="small"
            class="interview-details__menu-button"
          >
            <ev-icon
              name="ellipsis-h"
              color="gray60"
            />
          </ev-button>
        </template>
        <app-item-menu
          data-test="reschedule-button"
          @click="$emit('reschedule-interview', interview)"
        >
          <ev-icon
            name="edit-alt"
            color="gray60"
            class="interview-details__menu-icon"
          />
          {{ $t(`${langPath}.rescheduleLabel`) }}
        </app-item-menu>
        <app-item-menu
          data-test="cancellation-button"
          @click="$emit('cancel-interview', interview)"
        >
          <ev-icon
            name="calendar-slash"
            color="gray60"
            class="interview-details__menu-icon"
          />
          {{ $t(`${langPath}.cancelLabel`) }}
        </app-item-menu>
      </app-dropdown-menu>
    </div>
  </div>
</template>

<script>
import ApplicantAvatar from '@/components/Applicants/ApplicantAvatar'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvButton from '@revelotech/everestV2/EvButton'
import InterviewDateTime from './InterviewDateTime'
import AppDropdownMenu from '@/components/App/AppDropdownMenu'
import AppItemMenu from '@/components/App/AppItemMenu'

export default {
  name: 'InterviewDetails',
  components: {
    EvButton,
    EvIcon,
    ApplicantAvatar,
    InterviewDateTime,
    AppDropdownMenu,
    AppItemMenu
  },
  props: {
    interview: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      showMenu: false
    }
  },
  methods: {
    handleMouseover () {
      this.showMenu = true
    },
    handleMouseleave () {
      this.showMenu = false
    }
  }
}
</script>

<style lang="scss" scoped>
.interview-details {
  @include margin(bottom, 4);

  align-items: center;
  display: flex;
  min-height: 84px;
  padding: 2*$base 4*$base;

  &:hover {
    background: $gray5;
    cursor: pointer;
  }

  &__candidate-name {
    font: var(--tx-body);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__position-title {
    color: var(--bg-gray60);
    font: var(--tx-caption);
    height: $base*5;
  }

  &__menu-icon {
    margin-right: $base;
  }

  &__info {
    @include margin(left, 4);

    display: block;
    min-width: 45*$base;

    span {
      display: block;
    }
  }

  &__item {
    &--left {
      flex-basis: 0;
      flex-grow: 2;
    }

    &--right {
      margin-bottom: $base*8;
      max-width: 52px;
      text-align: right;
    }
  }

  &__menu-button {
    height: $base*9;

    &:focus {
      outline: none;
    }
  }

  &__actionable-icon {
    @include margin(left, 6);

    &:hover {
      i {
        color: $black;
      }
    }
  }

  &__timestamp {
    font: var(--tx-caption);
  }
}
</style>
