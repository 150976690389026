<template>
  <ev-link
    data-test="guide-link"
    :a-href="link"
    target="_blank"
    variant="tertiary"
    color="blue"
    size="small"
    class="how-to-use-your-schedule"
  >
    {{ $t(`${langPath}.text`) }}
    <ev-icon
      color="blue"
      name="info-circle"
      size="mini"
    />
  </ev-link>
</template>

<script>
import EvLink from '@revelotech/everestV2/EvLink'
import EvIcon from '@revelotech/everestV2/EvIcon'
import schedule from '@/helpers/constants/schedule-guide-url'

export default {
  name: 'HowToUseYourSchedule',
  components: {
    EvLink,
    EvIcon
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    link () {
      return schedule.scheduleGuideUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.how-to-use-your-schedule {
  display: inline-block;
  padding-top: 3*$base;
}
</style>
