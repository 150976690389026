<template>
  <div class="highlighted-interview">
    <div class="highlighted-interview__first-row">
      <interview-date-time
        class="highlighted-interview__timestamp"
        :starts-at="interview.scheduledStartAt"
        data-test="interview-timestamp"
      />
      <interview-badge
        :medium="interview.medium"
        :medium-info="interview.mediumInfo"
        data-test="interview-badge"
        class="highlighted-interview__item--right"
      />
    </div>
    <div
      class="highlighted-interview__candidate-info"
      data-test="candidate-info"
      @mouseover="handleMouseover"
      @mouseleave="handleMouseleave"
    >
      <span
        data-test="candidate-avatar-link"
        @click="$emit('open-candidate-modal', interview)"
      >
        <applicant-avatar
          class="highlighted-interview__candidate-avatar"
          :avatar-url="interview.candidateAvatar"
          :name="interview.candidateName"
          data-test="candidate-avatar"
        />
      </span>
      <section
        class="highlighted-interview__details highlighted-interview__item--left"
      >
        <span
          class="highlighted-interview__candidate-name"
          data-test="candidate-name"
          @click="$emit('open-candidate-modal', interview)"
        >
          {{ interview.candidateName }}
        </span>
        <span
          class="highlighted-interview__position-title"
          data-test="position-title"
          @click="$emit('open-position', interview)"
        >
          {{ $t(`${langPath}.position`) }}: {{ interview.positionTitle }}
        </span>
      </section>
      <div class="highlighted-interview__item--right">
        <app-dropdown-menu
          v-if="showMenu"
          data-test="menu"
        >
          <template slot="title">
            <ev-button
              variant="tertiary"
              color="blue"
              size="small"
              class="highlighted-interview__menu-button"
            >
              <ev-icon
                name="ellipsis-h"
                color="gray60"
              />
            </ev-button>
          </template>
          <app-item-menu
            data-test="reschedule-button"
            @click="$emit('reschedule-interview', interview)"
          >
            <ev-icon
              name="edit-alt"
              color="gray60"
              class="highlighted-interview__menu-icon"
            />
            {{ $t(`${langPath}.rescheduleLabel`) }}
          </app-item-menu>
          <app-item-menu
            data-test="cancellation-button"
            @click="$emit('cancel-interview', interview)"
          >
            <ev-icon
              name="calendar-slash"
              color="gray60"
              class="highlighted-interview__menu-icon"
            />
            {{ $t(`${langPath}.cancelLabel`) }}
          </app-item-menu>
        </app-dropdown-menu>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicantAvatar from '@/components/Applicants/ApplicantAvatar'
import AppDropdownMenu from '@/components/App/AppDropdownMenu'
import EvButton from '@revelotech/everestV2/EvButton'
import EvIcon from '@revelotech/everestV2/EvIcon'
import InterviewBadge from './InterviewBadge'
import InterviewDateTime from './InterviewDateTime'

export default {
  name: 'HighlightedInterview',
  components: {
    ApplicantAvatar,
    AppDropdownMenu,
    EvButton,
    EvIcon,
    InterviewBadge,
    InterviewDateTime
  },
  props: {
    interview: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      showMenu: false
    }
  },
  methods: {
    handleMouseover () {
      this.showMenu = true
    },
    handleMouseleave () {
      this.showMenu = false
    }
  }
}
</script>

<style lang="scss" scope>
.highlighted-interview {
  align-items: center;

  &__first-row {
    display: flex;
    margin-bottom: $base*4;
    padding: 0 4*$base;
  }

  &__candidate-info {
    display: flex;
    margin-bottom: $base*2;
    padding: 4*$base 4*$base;

    &:hover {
      background: $gray5;
      cursor: pointer;
    }
  }

  &__menu-icon {
    margin-right: $base;
  }

  &__details {
    display: flex;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 2;
    margin-left: $base*4;
    margin-right: $base*4;

    &:hover {
      cursor: pointer;
    }
  }

  &__candidate-name {
    font: var(--tx-body);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__candidate-avatar {
    cursor: pointer;
  }

  &__menu-button {
    height: $base*9;

    &:focus {
      outline: none;
    }
  }

  &__position-title {
    color: var(--bg-gray60);
    font: var(--tx-caption);
  }

  &__timestamp {
    flex-grow: 3;
    font: var(--tx-title-3);
    font-weight: 400;
    min-width: $base*38;
  }

  &__details > * {
    display: block;
    height: 100%;
  }

  &__item {
    &--right {
      max-width: 52px;
      text-align: right;
    }
  }
}
</style>
